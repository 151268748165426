<template>
  <b-card
    v-if="data"
    no-body
  >
    <b-overlay
      :show="show"
      rounded="sm"
    >

      <b-card-header>
        <h4 class="mb-0">
          License Duration
        </h4>
        <b-card-text class="font-medium-5 mb-0">
          <feather-icon
            v-b-tooltip.hover.top="'Your days left with your current Package'"
            icon="HelpCircleIcon"
            size="21"
            class="text-muted cursor-pointer"
          />
        </b-card-text>
      </b-card-header>

      <!-- apex chart -->
      <vue-apex-charts
        type="radialBar"
        height="245"
        class="my-2"
        :options="goalOverviewRadialBar"
        :series="series"
      />
      <b-row class="text-center mx-0">
        <b-col
          cols="6"
          class="border-top border-right d-flex align-items-between flex-column py-1"
        >
          <b-card-text class="text-muted mb-0">
            Days left
          </b-card-text>
          <h3 class="font-weight-bolder mb-0">
            {{ completed }}
          </h3>
        </b-col>

        <b-col
          cols="6"
          class="border-top d-flex align-items-between flex-column py-1"
        >
          <b-card-text class="text-muted mb-0">
            Total Days
          </b-card-text>
          <h3 class="font-weight-bolder mb-0">
            {{ inProgress }}
          </h3>
        </b-col>
      </b-row>
      <template #overlay>
        <div class="text-center">
          <b-icon
            icon="cash"
            font-scale="3"
            animation="pulse"
          />
          <p id="cancel-label">
            Please buy a license
          </p>
        </div></template>
    </b-overlay>

  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BRow, BCol, BCardText, VBTooltip, BOverlay, BIconCash, BIcon,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import { getUserData } from '@/auth/utils'
// import firebase from 'firebase'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    BIconCash,
    BIcon,
    BRow,
    BCardText,
    BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      completed: 230,
      inProgress: 70,
      series: [0],
      show: false,
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$goalStrokeColor2],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '77%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2.86rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
    }
  },
  mounted() {
    const userData = getUserData()
    // console.log(userData.id)
    const sx = this.$firebase.firestore()
    sx.collection('User')
      .doc(userData.id)
      .get()
      .then(snap => {
        // console.log(snap.data().Package)
        if (snap.data().Package === 'None') {
          this.show = true
        } else {
          // eslint-disable-next-line prefer-destructuring
          this.inProgress = snap.data().License.TotalDays
          // eslint-disable-next-line prefer-destructuring
          this.completed = snap.data().License.DaysLeft
          console.log(snap.data().License.DaysLeft / snap.data().License.TotalDays)
          this.series = ([100 - ((snap.data().License.DaysLeft / snap.data().License.TotalDays) * 100).toFixed(2)])
        }
      })
  },
}
</script>
