<template>
  <b-card
    v-if="data"
    no-body
  >
    <b-card-header>
      <b-card-title class="ml-25">
        Release Timeline
      </b-card-title>
    </b-card-header>

    <!-- timeline -->
    <b-card-body>
      <app-timeline>
        <app-timeline-item variant="primary">
          <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0">
            <h6>{{ data.step1.title }}</h6>
            <small class="timeline-item-time text-nowrap text-muted ml-1">{{ data.step1.duration }}</small>
          </div>
          <p>{{ data.step1.subtitle }}</p>
        </app-timeline-item>

        <app-timeline-item variant="warning">
          <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mt-n2">
            <h6>{{ data.step2.title }}</h6>
            <small class="timeline-item-time text-nowrap text-muted ml-1">{{ data.step2.duration }}</small>
          </div>
          <b-list-group class="list-group-circle">
            <b-list-group-item
              v-for="subtitle in data.step2.subtitles"
              :key="subtitle"
              class="text-body mb-n1"
            >
              {{ subtitle }}
            </b-list-group-item>
          </b-list-group>

        </app-timeline-item>

        <app-timeline-item variant="warning">
          <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">
            <h6>{{ data.step3.title }}</h6>
            <small class="timeline-item-time text-nowrap text-muted ml-1">{{ data.step3.duration }}</small>
          </div>
          <b-list-group class="list-group-circle">
            <b-list-group-item
              v-for="subtitle in data.step3.subtitles"
              :key="subtitle"
              class="text-body mb-n1"
            >
              {{ subtitle }}
            </b-list-group-item>
          </b-list-group>
        </app-timeline-item>
        <!--        <app-timeline-item variant="info">-->
        <!--          <div class="d-flex justify-content-between flex-sm-row flex-column mb-sm-0 mb-1">-->
        <!--            <h6>{{ data.step4.title }}</h6>-->
        <!--            <small class="timeline-item-time text-nowrap text-muted ml-1">{{ data.step4.duration }}</small>-->
        <!--          </div>-->
        <!--          <b-list-group class="list-group-circle">-->
        <!--            <b-list-group-item-->
        <!--                class="text-body"-->
        <!--            >-->
        <!--              Raid-Bot-->
        <!--            </b-list-group-item>-->
        <!--            <b-list-group-item-->
        <!--                class="text-body mt-n1"-->

        <!--            >-->
        <!--              Follower-Rework-->
        <!--            </b-list-group-item>-->
        <!--          </b-list-group>-->
        <!--        </app-timeline-item>-->
        <!-- <app-timeline-item
          :title="data.step4.title"
          :subtitle="data.step4.subtitle"
          :time="data.step4.duration"
          variant="danger"
        /> -->
      </app-timeline>
      <!--/ timeline -->
    </b-card-body>
  </b-card>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BImg,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAvatar,
  BAvatarGroup,
  VBTooltip,
  BListGroupItem,
  BListGroup,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardHeader,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BAvatar,
    BMediaBody,
    BListGroup,
    BListGroupItem,
    BMediaAside,
    BAvatarGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
