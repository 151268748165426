<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height justify-content-center">
      <b-col

        md="12"
      >
        <ecommerce-medal :data="data.congratulations" />
      </b-col>
      <!--      xl="4"-->
      <!--        <ecommerce-statistics :data="data.statisticsItems" />-->

    </b-row>
    <b-row class="match-height">
      <b-col
        xl="4"
        md="6"
      >
        <ecommerce-goal-overview :data="data.goalOverview" />

      </b-col>
      <!--      <b-col>-->
      <!--        <license-duration :data="data.license" />-->
      <!--      </b-col>-->
      <b-col>
        <analytics-timeline :data="data1.timeline" />
      </b-col>
    </b-row>
    <!--/ Goal Overview Card -->

    <!-- Transaction Card -->
    <!--    <b-col-->
    <!--      lg="4"-->
    <!--      md="6"-->
    <!--    >-->
    <!--      <ecommerce-transactions :data="data.transactionData" />-->
    <!--    </b-col>-->
    <!--/ Transaction Card -->

  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'
import EcommerceMedal from './EcommerceMedal.vue'
import AnalyticsTimeline from './AnalyticsTimeline.vue'
// import EcommerceStatistics from './EcommerceStatistics.vue'
import EcommerceGoalOverview from './EcommerceGoalOverview.vue'
import LicenseDuration from './LicenseDuration.vue'

// import EcommerceTransactions from './EcommerceTransactions.vue'

export default {
  components: {
    BRow,
    BCol,

    EcommerceMedal,
    // EcommerceStatistics,
    EcommerceGoalOverview,
    // eslint-disable-next-line vue/no-unused-components
    LicenseDuration,
    AnalyticsTimeline,
  },
  data() {
    return {
      data: {},
      data1: {},
    }
  },
  created() {
    // data
    // this.$swal({
    //   title: 'Make sure to join our new Discord Server! https://discord.gg/g5NhFgktHQ ',
    //   text: 'Sadly our old Discord got deleted (for now), please join our new Discord! https://discord.gg/g5NhFgktHQ',
    //   icon: 'success',
    //   customClass: {
    //     confirmButton: 'btn btn-primary',
    //   },
    //   buttonsStyling: false,
    // })
    this.$http.get('/ecommerce/data')
      .then(response => {
        this.data = response.data

        // ? Your API will return name of logged in user or you might just directly get name of logged in user
        // ? This is just for demo purpose
        const userData = getUserData()
        this.data.congratulations.name = userData.fullName.split(' ')[0] || userData.username
      })
    this.$http.get('/analytics/data')
      .then(response => { this.data1 = response.data })
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
