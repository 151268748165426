<template>
  <b-card
    v-if="data"
    class="card-congratulation-medal"
  >
    <h5>Congratulations 🎉 {{ data.name }}!</h5>
    <b-card-text class="font-small-3">
      Your current Subscription:
    </b-card-text>
    <h3 class="mb-75 mt-2 pt-50">
      <b-link>{{ kFormatter(this.package2) }}</b-link>
    </h3>
    <b-button
      v-if="enterlicense"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="mr-3"
      variant="primary"
      @click="toPricing"
    >
      View Packages
    </b-button>
    <b-img
      :src="require('@/assets/images/illustration/badge.svg')"
      class="congratulation-medal"
      alt="Medal Pic"

    />
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BLink, BButton, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { kFormatter } from '@core/utils/filter'
import { getUserData } from '@/auth/utils'
// import firebase from 'firebase'

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      package2: 'None',
      enterlicense: false,
    }
  },
  mounted() {
    const userData = getUserData()
    // console.log(`MEDALFIREBASE${firebase.auth().currentUser.uid}`)
    // const userid = firebase.auth().currentUser.uid
    // console.log(userData.id)
    const sx = this.$firebase.firestore()
    sx.collection('User')
      .doc(userData.id)
      .get()
      .then(snap => {
        // console.log(snap.data().Package)
        if (snap.data().Package === 'None') {
          this.enterlicense = true
        }

        this.package2 = snap.data().Package
      })
  },
  methods: {
    kFormatter,
    toPricing() {
      this.$router.push('/pages/pricing')
    },
    toBotPanel() {
      this.$router.push('/pages/Panel')
    },
  },
}
</script>
